import React from 'react'
import styled from 'styled-components'

// Elements
import ButtonOrangeBig from 'components/elements/ButtonOrangeBig'

const StyledCtaOnLine = styled.section``

const ButtonWrapper = styled.div`
  @media (min-width: 992px) {
    top: -26px;
  }

  @media (max-width: 991px) {
    top: -22px;
  }
`

interface CtaOnLineProps {
  fields: any
}

const CtaOnLine: React.FC<CtaOnLineProps> = ({ fields }) => (
  <StyledCtaOnLine>
    <div className="container position-relative">
      <ButtonWrapper className="row position-absolute w-100">
        <div className="col-lg-6 text-lg-right">
          <ButtonOrangeBig
            color={fields?.buttonColor || fields?.button_color}
            to={fields?.link?.url}
          >
            {fields?.link?.title}
          </ButtonOrangeBig>
        </div>
      </ButtonWrapper>
    </div>
  </StyledCtaOnLine>
)

export default CtaOnLine
